/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { ThemeOptions, Palette } from "@material-ui/core";

/**
 * Style overrides for Material UI components.
 */
export function createComponents(palette: Palette): ThemeOptions["components"] {
  return {
    // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/Button
    MuiButton: {
      styleOverrides: {
        root: {
          // letterSpacing: "0.0625rem",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          backgroundColor: palette.secondary.main,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: palette.secondary.dark,
          },
        },
      },
    },
    // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/ButtonGroup
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  } as ThemeOptions["components"];
}
