/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import { Box, Button, Drawer, DrawerProps, Toolbar } from "@material-ui/core";
import { Explore, Home, LibraryBooks } from "@material-ui/icons";
import * as React from "react";
import { useLocation, useNavigate } from "../hooks";

const drawerWidth = 72;

function NavButton(props: {
  icon: React.FunctionComponent;
  label: string;
  href: string;
}): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Button
      sx={{
        borderRadius: 0,
        color: (theme) =>
          pathname === props.href
            ? theme.palette.primary.main
            : theme.palette.grey[500],
        "& .MuiButton-label": {
          flexDirection: "column",
          justifyContent: "center",
          fontSize: "0.75rem",
        },
        "& .MuiButton-startIcon": {
          margin: 0,
        },
        mb: 1,
      }}
      href={props.href}
      onClick={navigate}
      startIcon={React.createElement(props.icon)}
      children={props.label}
    />
  );
}

type AppDrawerProps = Omit<DrawerProps, "children">;

export function AppDrawer(props: AppDrawerProps): JSX.Element {
  const { sx, ...other } = props;

  return (
    <Drawer
      sx={{
        "&.MuiDrawer-docked": {
          width: "72px",
        },
        "& .MuiPaper-root": {
          width: "72px",
        },
        ...sx,
      }}
      variant="permanent"
      {...other}
    >
      <Toolbar />
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <NavButton icon={Home} href="/" label="Home" />
        <NavButton icon={Explore} href="/explore" label="Explore" />
        <NavButton icon={LibraryBooks} href="/library" label="Library" />
      </Box>
    </Drawer>
  );
}
