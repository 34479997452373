/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type adminWorkListQueryVariables = {};
export type adminWorkListQueryResponse = {
    readonly me: {
        readonly admin: boolean | null;
    } | null;
    readonly works: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly slug: string;
                readonly title: string;
                readonly subtitle: string | null;
                readonly summary: string;
                readonly updatedAt: string | null;
                readonly picture: {
                    readonly filename: string;
                    readonly width: number | null;
                    readonly height: number | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type adminWorkListQuery = {
    readonly response: adminWorkListQueryResponse;
    readonly variables: adminWorkListQueryVariables;
};



/*
query adminWorkListQuery {
  me {
    admin
    id
  }
  works(first: 9999) {
    edges {
      node {
        id
        slug
        title
        subtitle
        summary
        updatedAt
        picture {
          filename
          width
          height
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 9999
    }
  ],
  "concreteType": "WorkConnection",
  "kind": "LinkedField",
  "name": "works",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Work",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subtitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "summary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updatedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Picture",
              "kind": "LinkedField",
              "name": "picture",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "filename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "works(first:9999)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "adminWorkListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "adminWorkListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "cfa2ffb05a4142c6981a26e628857b3f",
    "id": null,
    "metadata": {},
    "name": "adminWorkListQuery",
    "operationKind": "query",
    "text": "query adminWorkListQuery {\n  me {\n    admin\n    id\n  }\n  works(first: 9999) {\n    edges {\n      node {\n        id\n        slug\n        title\n        subtitle\n        summary\n        updatedAt\n        picture {\n          filename\n          width\n          height\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a66fe0996ff19a32ef9dfa12d94bba90';
export default node;
