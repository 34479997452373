/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import {
  AppBar,
  AppBarProps,
  Avatar,
  Button,
  Chip,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowDropDown,
  Brightness4,
  Menu as MenuIcon,
  NotificationsNone,
} from "@material-ui/icons";
import * as React from "react";
import {
  useCurrentUser,
  useCustomerChat,
  useHistory,
  useLoginDialog,
  useNavigate,
} from "../hooks";
import { Logo } from "../icons";
import { NotificationsMenu, UserMenu } from "../menu";
import { AppSearch } from "./AppSearch";

type AppToolbarProps = AppBarProps & {
  onChangeTheme: () => void;
  back?: string;
};

export function AppToolbar(props: AppToolbarProps): JSX.Element {
  const { sx, onChangeTheme, back, ...other } = props;
  const rootRef = React.useRef<HTMLDivElement>(null);
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);
  const showSearch = useMediaQuery("(min-width:600px)");

  const [anchorEl, setAnchorEl] = React.useState({
    userMenu: null as HTMLElement | null,
    notifications: null as HTMLElement | null,
  });

  const loginDialog = useLoginDialog();
  const navigate = useNavigate();
  const history = useHistory();
  const user = useCurrentUser();

  useCustomerChat();

  function openNotificationsMenu() {
    setAnchorEl((x) => ({ ...x, notifications: menuAnchorRef.current }));
  }

  function closeNotificationsMenu() {
    setAnchorEl((x) => ({ ...x, notifications: null }));
  }

  function openUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: menuAnchorRef.current }));
  }

  function closeUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: null }));
  }

  function signIn(event: React.MouseEvent): void {
    event.preventDefault();
    loginDialog.show();
  }

  function goBack(event: React.MouseEvent) {
    event.preventDefault();
    history.goBack(back);
  }

  function handleDrawerOpen() {}

  return (
    <AppBar
      ref={rootRef}
      sx={{
        display: "grid",
        gridTemplateColumns: "72px 1fr",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
      color="default"
      elevation={0}
      {...other}
    >
      <IconButton
        sx={{ placeSelf: "center", m: "0 auto" }}
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        children={<MenuIcon />}
      />
      <Toolbar sx={{ paddingLeft: "0 !important" }}>
        {/* App Logo */}

        {back && (
          <Button
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "18px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
            color="secondary"
            variant="contained"
            onClick={goBack}
            href={back}
            startIcon={<ArrowBack />}
            children="Back"
          />
        )}

        {!back && (
          <Typography
            variant="h1"
            sx={{
              color: (theme) => theme.palette.primary.main,
              marginTop: "-6px",
              marginRight: (theme) => theme.spacing(2),
            }}
          >
            <Link color="inherit" underline="none" href="/" onClick={navigate}>
              <Logo sx={{ marginTop: "12px", width: 38, height: 38 }} />
            </Link>
          </Typography>
        )}

        {showSearch && !back && <AppSearch />}

        <span style={{ flexGrow: 1, minWidth: 16 }} />

        {/* Account related controls (icon buttons) */}

        {user && (
          <Chip
            sx={{
              height: 40,
              borderRadius: 20,
              fontWeight: 600,
              backgroundColor: (theme) => theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.dark,
              },
              ".MuiChip-avatar": { width: 32, height: 32 },
            }}
            component="a"
            avatar={
              <Avatar alt={user.name || ""} src={user.picture || undefined} />
            }
            label={getFirstName(user.name || "")}
            href={`/@${user.username}`}
            onClick={navigate}
          />
        )}
        {user && (
          <IconButton
            sx={{
              marginLeft: (theme) => theme.spacing(1),
              backgroundColor: (theme) => theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.dark,
              },
              width: 40,
              height: 40,
            }}
            children={<NotificationsNone />}
            onClick={openNotificationsMenu}
          />
        )}
        {user && (
          <IconButton
            ref={menuAnchorRef}
            sx={{
              marginLeft: (theme) => theme.spacing(1),
              backgroundColor: (theme) => theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.dark,
              },
              width: 40,
              height: 40,
            }}
            children={<ArrowDropDown />}
            onClick={openUserMenu}
          />
        )}
        {!user && (
          <Button
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "18px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
            color="secondary"
            variant="contained"
            href="/auth/google"
            onClick={signIn}
            children="Sign in"
          />
        )}
        {!user && (
          <IconButton
            ref={menuAnchorRef}
            sx={{
              marginLeft: (theme) => theme.spacing(1),
              backgroundColor: (theme) => theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.dark,
              },
              width: 40,
              height: 40,
            }}
            children={<Brightness4 />}
            onClick={onChangeTheme}
          />
        )}
      </Toolbar>

      {/* Pop-up menus */}

      <NotificationsMenu
        anchorEl={anchorEl.notifications}
        onClose={closeNotificationsMenu}
        PaperProps={{ sx: { marginTop: "8px" } }}
      />
      <UserMenu
        anchorEl={anchorEl.userMenu}
        onClose={closeUserMenu}
        PaperProps={{ sx: { marginTop: "8px" } }}
        onChangeTheme={onChangeTheme}
      />
    </AppBar>
  );
}

function getFirstName(displayName: string): string {
  return displayName?.split(" ")[0] ?? "";
}
