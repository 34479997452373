import type {
  Typography,
  TypographyOptions,
} from "@material-ui/core/styles/createTypography";

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    textFontFamily: string;
  }

  interface TypographyOptions {
    textFontFamily: string;
  }
}

export const defaultFontFamily = [
  `-apple-system`,
  `BlinkMacSystemFont`,
  `"Segoe UI"`,
  `Helvetica`,
  `Arial`,
  `sans-serif`,
  `"Apple Color Emoji"`,
  `"Segoe UI Emoji"`,
].join(",");

export const textFontFamily = [
  `charter`,
  `Georgia`,
  `Cambria`,
  `"Times New Roman"`,
  `Times`,
  `serif`,
].join(",");

export const typography: TypographyOptions = {
  fontFamily: defaultFontFamily,
  textFontFamily: textFontFamily,
  // h1: { fontFamily: textFontFamily },
  // h2: { fontFamily: textFontFamily },
  // h3: { fontFamily: textFontFamily },
  // h4: { fontFamily: textFontFamily },
  // h5: { fontFamily: textFontFamily },
  // h6: { fontFamily: textFontFamily },
};

export type { Typography, TypographyOptions };
