/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import admin from "../admin";
import account from "./account";
import explore from "./explore";
import home from "./home";
import legal from "./legal";
import library from "./library";
import user from "./user";
import work from "./work";

/**
 * The list of application routes (pages).
 */
export default [
  home,
  ...explore,
  ...library,
  account,
  ...legal,
  user,
  ...admin,
  ...work,
] as const;
