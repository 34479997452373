/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { DialogProps } from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  AlternateEmail,
  Close,
  Facebook as FacebookIcon,
  Link as LinkIcon,
  Lock,
  Person,
  Twitter as TwitterIcon,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import * as React from "react";
import {
  fetchQuery,
  graphql,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { createOperationDescriptor, getRequest, ROOT_ID } from "relay-runtime";
import { useErrors, useLoginDialog } from "../hooks";
import { Facebook, Google, Logo } from "../icons";
import { LoginDialogResetMutation } from "./__generated__/LoginDialogResetMutation.graphql";
import { LoginDialogSignInMutation } from "./__generated__/LoginDialogSignInMutation.graphql";
import { LoginDialogSignUpMutation } from "./__generated__/LoginDialogSignUpMutation.graphql";
import { LoginDialogUserQuery } from "./__generated__/LoginDialogUserQuery.graphql";

// An email has been sent. Please click the link when you get it.

const meQuery = graphql`
  query LoginDialogMeQuery {
    me {
      id
      username
      email
      emailVerified
      name
      picture
      givenName
      familyName
      timeZone
      locale
      createdAt
      updatedAt
      lastLogin
    }
  }
`;

const userQuery = graphql`
  query LoginDialogUserQuery($username: String!) {
    user(username: $username) {
      id
      email
    }
  }
`;

const signInMutation = graphql`
  mutation LoginDialogSignInMutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      me {
        id
        name
        email
        picture
        username
      }
    }
  }
`;

const signUpMutation = graphql`
  mutation LoginDialogSignUpMutation($input: SignUpInput!) {
    signUp(input: $input) {
      me {
        id
        name
        email
        picture
        username
      }
    }
  }
`;

const resetMutation = graphql`
  mutation LoginDialogResetMutation(
    $email: String
    $password: String
    $token: String
  ) {
    resetPassword(email: $email, password: $password, token: $token)
  }
`;

type User = LoginDialogUserQuery["response"]["user"];
type LoginDialogProps = Omit<DialogProps, "children">;
type Input = {
  email?: string;
  username?: string;
  name?: string;
  password?: string;
  timeZone?: string;
  languages?: readonly string[];
};

enum Screen {
  Continue,
  SignIn,
  SignUp,
  Reset,
  ResetOK,
}

// Pop-up window for Google/Facebook authentication
let loginWindow: WindowProxy | null = null;

export function LoginDialog(): JSX.Element {
  const isTouchScreen = useMediaQuery("(pointer: coarse)");
  const loginDialog = useLoginDialog(true);
  const [screen, setScreen] = React.useState<Screen>(Screen.Continue);
  const [pswd, setPswd] = React.useState(false);
  const [fetchInFlight, setFetchInFlight] = React.useState(false);
  const [input, setInput] = React.useState<Input>({});
  const [user, setUser] = React.useState<User | undefined>();
  const [errors, setErrors] = useErrors();
  const relay = useRelayEnvironment();
  const theme = useTheme();

  React.useEffect(() => {
    if (loginDialog.open === false) {
      setScreen(Screen.Continue);
      setPswd(false);
      setFetchInFlight(false);
      setInput({});
      setUser(undefined);
      setErrors();
    }
  }, [loginDialog.key, loginDialog.open]);

  // Start listening for a response from the auth pop-up window
  React.useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (
        event.origin === window.location.origin &&
        event.source === loginWindow
      ) {
        if (event.data.error) {
          setErrors((x) => ({ ...x, _: event.data.error }));
        } else if (event.data) {
          const request = getRequest(meQuery);
          const operation = createOperationDescriptor(request, {});
          relay.commitPayload(operation, event.data);
          loginDialog.hide();
        }
      }
    }
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, [relay]);

  const [signIn, signInInFlight] = useMutation<LoginDialogSignInMutation>(
    signInMutation,
  );
  const [signUp, signUpInFlight] = useMutation<LoginDialogSignUpMutation>(
    signUpMutation,
  );
  const [reset, resetInFlight] = useMutation<LoginDialogResetMutation>(
    resetMutation,
  );

  function handleContinue(event: React.FormEvent): void {
    event.preventDefault();
    setFetchInFlight(true);
    fetchQuery<LoginDialogUserQuery>(
      relay,
      userQuery,
      { username: input.email || "" },
      { force: true },
    )
      .toPromise()
      .then(
        (x) => {
          setUser(x.user);
          setFetchInFlight(false);
        },
        (err) => {
          console.error(err);
          setFetchInFlight(false);
        },
      );
  }

  function handleSignIn(event: React.FormEvent) {
    event.preventDefault();
    setErrors();
    signIn({
      variables: {
        email: input.email || "",
        password: input.password || "",
      },
      onCompleted(_, errors) {
        setErrors(errors?.[0]);
      },
      updater(store) {
        const me = store.getRootField("signIn")?.getLinkedRecord("me");
        if (me) {
          store.get(ROOT_ID)?.setLinkedRecord(me || null, "me");
          loginDialog.hide();
        }
      },
    });
  }

  function handleRegister(event: React.FormEvent) {
    event.preventDefault();
    setErrors();
    signUp({
      variables: {
        input: {
          ...input,
          languages: (navigator.languages || [navigator.language]) as string[],
          timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        },
      },
      onCompleted(_, errors) {
        setErrors(errors?.[0]);
      },
      updater(store) {
        const me = store.getRootField("signUp")?.getLinkedRecord("me");
        if (me) {
          store.get(ROOT_ID)?.setLinkedRecord(me, "me");
          loginDialog.hide();
        }
      },
    });
  }

  function sendRecoveryLink(event: React.FormEvent) {
    event.preventDefault();
    setErrors();
    reset({
      variables: { email: String(input.email) },
      onCompleted(_, errors) {
        setErrors(errors?.[0]);
      },
    });
  }

  function togglePasswordVisibility() {
    setPswd((visible) => !visible);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    let value = event.target.value;

    // Remove characters that are not allowed in usernames
    if (name === "username") {
      value = value
        .replace(/[^\w._]/g, "")
        .replace(/^[._]/, "")
        .replace(/^\d/, "");
    }

    setInput((x) => ({ ...x, [name]: value }));
    setErrors((x) => (x[name] ? { ...x, [name]: undefined } : x));
  }

  function enablePasswordRecovery(event: React.MouseEvent) {
    event.preventDefault();
    setScreen(Screen.Reset);
  }

  function handleAuth(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const url = event.currentTarget.href;

    if (loginWindow === null || loginWindow.closed) {
      const width = 520;
      const height = 600;
      const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;
      const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
      loginWindow = window.open(
        url,
        "login",
        `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`,
      );
    } else {
      loginWindow.focus();
      loginWindow.location.href = url;
    }
  }

  const loading =
    fetchInFlight || signInInFlight || signUpInFlight || resetInFlight;

  let title = "To continue, sign in to Goodparts.";
  let action = handleSignIn;
  let actionText = "Log in";
  const show = {
    email: false,
    username: false,
    usernameErr: false,
    name: false,
    password: false,
    privacy: false,
    passwordRecLink: false,
    social: false,
  };

  if (user === null) {
    title = "Let’s get you started!";
    actionText = "Register";
    action = handleRegister;
    show.username = true;
    show.usernameErr = !errors.username;
    show.name = true;
    show.password = true;
    show.privacy = true;
    show.passwordRecLink = false;
  } else if (user === undefined) {
    actionText = "Continue";
    action = handleContinue;
    show.email = true;
    show.passwordRecLink = false;
    show.social = true;
  } else {
    if (screen === Screen.Reset) {
      title = "Don't worry, happens to the best of us.";
      actionText = "Email me a recovery link";
      action = sendRecoveryLink;
    }
    show.password = !(screen === Screen.Reset || screen === Screen.ResetOK);
    show.passwordRecLink = !(
      screen === Screen.Reset || screen === Screen.ResetOK
    );
    show.email = screen === Screen.Reset;
  }

  return (
    <Dialog
      key={loginDialog.key}
      open={loginDialog.open}
      onClose={loginDialog.hide}
      maxWidth="xs"
      scroll="body"
      fullScreen
    >
      <DialogTitle sx={{ textAlign: "right" }}>
        <Tooltip title="Close">
          <IconButton
            sx={{ marginTop: "-8px", marginRight: "-8px" }}
            children={<Close />}
            onClick={loginDialog.hide}
          />
        </Tooltip>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          "&>*": {
            maxWidth: "320px",
            width: "100%",
          },
        }}
      >
        <Logo
          sx={{
            display: "block",
            margin: {
              xs: "0 auto 2rem",
              md: "8vh auto 2rem",
            },
            color: theme.palette.primary.main,
            fontSize: "4rem",
          }}
        />

        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "600",
            marginBottom: (theme) => theme.spacing(2),
          }}
          variant="h5"
          align="center"
          children={title}
        />

        <form id="login-form" onSubmit={action}>
          {show.email && (
            <TextField
              sx={{ marginBottom: (theme) => theme.spacing(2) }}
              InputProps={{
                sx: {
                  borderRadius: "21px",
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    children={<AlternateEmail />}
                  />
                ),
              }}
              inputProps={{
                sx: { paddingTop: "9.5px", paddingBottom: "9.5px" },
              }}
              name="email"
              type="email"
              placeholder="Email address"
              value={input.email || ""}
              onChange={handleChange}
              disabled={user === null}
              error={Boolean(errors.email)}
              helperText={<Errors>{errors.email}</Errors>}
              autoFocus={!isTouchScreen}
              fullWidth
              required
            />
          )}
          {show.username && (
            <TextField
              sx={{ marginBottom: errors.username ? theme.spacing(2) : "2px" }}
              InputProps={{
                sx: {
                  borderRadius: "21px",
                },
                startAdornment: (
                  <InputAdornment position="start" children={<LinkIcon />} />
                ),
              }}
              inputProps={{
                sx: { paddingTop: "9.5px", paddingBottom: "9.5px" },
              }}
              name="username"
              type="text"
              placeholder="Username"
              value={input.username || ""}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username?.join(" ")}
              autoFocus
              fullWidth
              required
            />
          )}
          {show.usernameErr && (
            <Typography
              sx={{
                paddingRight: "1rem",
                marginBottom: theme.spacing(2),
                color: theme.palette.primary.light,
                fontSize: "0.75rem",
                fontWeight: 500,
              }}
              align="right"
              children={`https://goodparts.org/@${
                input.username || "username"
              }`}
            />
          )}
          {show.name && (
            <TextField
              sx={{ marginBottom: (theme) => theme.spacing(2) }}
              InputProps={{
                sx: {
                  borderRadius: "21px",
                },
                startAdornment: (
                  <InputAdornment position="start" children={<Person />} />
                ),
              }}
              inputProps={{
                sx: { paddingTop: "9.5px", paddingBottom: "9.5px" },
              }}
              name="name"
              type="text"
              placeholder="Display Name"
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={<Errors>{errors.name}</Errors>}
              fullWidth
              required
            />
          )}
          {show.password && (
            <TextField
              sx={{ marginBottom: (theme) => theme.spacing(2) }}
              InputProps={{
                sx: {
                  borderRadius: "21px",
                },
                startAdornment: (
                  <InputAdornment position="start" children={<Lock />} />
                ),
                endAdornment: (
                  <InputAdornment
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                    position="end"
                    children={
                      <Tooltip title={pswd ? "Hide password" : "Show password"}>
                        {pswd ? <Visibility /> : <VisibilityOff />}
                      </Tooltip>
                    }
                  />
                ),
              }}
              inputProps={{
                sx: { paddingTop: "9.5px", paddingBottom: "9.5px" },
              }}
              name="password"
              type={pswd ? "text" : "password"}
              placeholder="Password"
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={<Errors>{errors.password}</Errors>}
              fullWidth
              required
            />
          )}
          {show.privacy && (
            <FormControlLabel
              sx={{
                "& .MuiIconButton-root": {
                  marginTop: "-10px",
                  marginLeft: "17px",
                },
                "& .MuiTypography-root": {
                  fontSize: "0.875rem",
                },
                marginBottom: theme.spacing(2),
              }}
              label={
                <React.Fragment>
                  I agree to the{" "}
                  <Link href="/terms" target="_blank" rel="noopener nofollow">
                    terms of use
                  </Link>{" "}
                  and
                  <br />
                  <Link href="/privacy" target="_blank" rel="noopener nofollow">
                    privacy policy
                  </Link>
                </React.Fragment>
              }
              control={<Checkbox name="agree" required />}
            />
          )}
        </form>

        <Button
          sx={{
            borderRadius: "21px",
            marginBottom: (theme) => theme.spacing(2),
            "& .MuiButton-endIcon": {
              marginLeft: "-12px",
            },
          }}
          type="submit"
          form="login-form"
          size="large"
          variant="contained"
          color="primary"
          children={<span style={{ flexGrow: 1 }} children={actionText} />}
          endIcon={loading && <CircularProgress color="inherit" size={16} />}
          disabled={loading}
          fullWidth
        />

        {/* Forgot password? */}
        {show.passwordRecLink && (
          <Typography
            sx={{ marginTop: "-6px", paddingLeft: "20px" }}
            variant="body2"
          >
            <Link href="/" onClick={enablePasswordRecovery}>
              Forgot password?
            </Link>
          </Typography>
        )}

        {/* -- OR -- */}
        <Box
          sx={{
            display: show.social ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            color: (theme) => theme.palette.text.disabled,
            marginBottom: (theme) => theme.spacing(2),
            "&:after,&:before": {
              content: `""`,
              display: "block",
              background: (theme) => theme.palette.text.disabled,
              width: "30%",
              height: "1px",
              margin: "0 10px",
            },
          }}
          children="OR"
        />

        {/* Sign in via Google/Facebook */}
        <Button
          sx={{
            display: show.social ? "inline-flex" : "none",
            borderRadius: "21px",
            marginBottom: (theme) => theme.spacing(2),
          }}
          size="large"
          variant="outlined"
          color="primary"
          children={
            <span style={{ flexGrow: 1, textAlign: "center" }}>
              Continue with Google
            </span>
          }
          startIcon={<Google style={{ fontSize: 28, margin: "-4px -8px" }} />}
          href="/auth/google"
          onClick={handleAuth}
          fullWidth
        />
        <Button
          sx={{
            display: show.social ? "inline-flex" : "none",
            borderRadius: "21px",
            marginBottom: (theme) => theme.spacing(2),
          }}
          size="large"
          variant="outlined"
          color="primary"
          children={
            <span style={{ flexGrow: 1, textAlign: "center" }}>
              Continue with Facebook
            </span>
          }
          startIcon={<Facebook style={{ fontSize: 28, margin: "-4px -8px" }} />}
          href="/auth/facebook"
          onClick={handleAuth}
          fullWidth
        />
      </DialogContent>

      {/* Follow us icons */}
      <Box sx={{ textAlign: "center", padding: "8px" }}>
        <Tooltip title="Follow us on Facebook">
          <IconButton
            sx={{
              color: theme.palette.text.disabled,
              margin: "0 -6px",
              "&:hover,&:active": {
                color: "#1877F2",
              },
            }}
            children={<FacebookIcon />}
            href="https://facebook.com/GoodpartsApp"
          />
        </Tooltip>
        <Tooltip title="Follow us on Twitter">
          <IconButton
            sx={{
              color: theme.palette.text.disabled,
              margin: "0 -6px",
              "&:hover,&:active": {
                color: "#1DA1F2",
              },
            }}
            children={<TwitterIcon />}
            href="https://twitter.com/GoodpartsApp"
          />
        </Tooltip>
      </Box>
    </Dialog>
  );
}

type ErrorsProps = {
  children?: string[];
};

function Errors(props: ErrorsProps): React.ReactNode {
  return props.children === undefined
    ? null
    : props.children.length === 1
    ? props.children[0]
    : props.children.reduce(
        (acc, err, i) =>
          acc.length === 0
            ? [<span key={i} children={err} />]
            : [...acc, <br key={`${i}.`} />, <span key={i} children={err} />],
        [] as JSX.Element[],
      );
}
