/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import {
  Box,
  CssBaseline,
  GlobalStyles,
  PaletteMode,
  Toolbar,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Action, Update } from "history";
import * as React from "react";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import { History, HistoryContext, LocationContext } from "../core/history";
import type { RouteResponse } from "../core/router";
import { resolveRoute } from "../core/router";
import { LoginDialog } from "../dialogs";
import theme from "../theme";
import { AppDrawer } from "./AppDrawer";
import { AppToolbar } from "./AppToolbar";
import { ErrorPage } from "./ErrorPage";

type AppProps = {
  history: History;
  relay: Environment;
  toolbar: RouteResponse["toolbar"];
};

export class App extends React.Component<AppProps> {
  state = {
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    action: Action.Pop,
    error: undefined as Error | undefined,
    theme: (window?.localStorage?.getItem("theme") === "dark"
      ? "dark"
      : "light") as PaletteMode,
  };

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  dispose?: () => void;

  componentDidMount(): void {
    const { history } = this.props;
    this.dispose = history.listen(this.renderPath);

    // Extract URL pathname from <html data-path="..">
    // in order to render correctly "cached" versions of the pages
    const el = document.documentElement;
    const pathname = el.getAttribute("path");
    const search = el.getAttribute("search") || "";
    const location = pathname
      ? { ...history.location, pathname, search }
      : history.location;
    this.renderPath({ location, action: Action.Pop });
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = this.state.route.title;
    }
    if (this.state.action === Action.Push) window.scrollTo(0, 0);
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  renderPath = async (ctx: Update): Promise<void> => {
    resolveRoute({
      path: ctx.location.pathname,
      relay: this.props.relay,
    }).then((route) => {
      if (route.error) console.error(route.error);
      this.setState({
        route,
        location: ctx.location,
        action: ctx.action,
        error: route.error,
      });
    });
  };

  handleChangeTheme = (): void => {
    this.setState((x: { theme: PaletteMode }) => {
      const theme = x.theme === "light" ? "dark" : "light";
      window.localStorage?.setItem("theme", theme);
      return { ...x, theme };
    });
  };

  render(): JSX.Element {
    const { history } = this.props;
    const { route, location, error } = this.state;

    if (error) {
      return (
        <ThemeProvider theme={theme[this.state.theme]}>
          <ErrorPage error={error} history={history} />;
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme[this.state.theme]}>
        <RelayEnvironmentProvider environment={this.props.relay}>
          <HistoryContext.Provider value={history}>
            <LocationContext.Provider value={location}>
              <CssBaseline />
              <GlobalStyles
                styles={{
                  "#root": {
                    display: "grid",
                    gridTemplateColumns: "auto minmax(0, 1fr)",
                    gridTemplateRows: "auto minmax(0, 1fr)",
                    gridTemplateAreas: `"toolbar toolbar" "drawer content"`,
                    minHeight: "100vh",
                  },
                }}
              />
              <AppToolbar
                sx={{ gridArea: "toolbar" }}
                onChangeTheme={this.handleChangeTheme}
                {...(route ? route.toolbar : this.props.toolbar)}
              />
              <Toolbar sx={{ gridArea: "toolbar" }} />
              <AppDrawer sx={{ gridArea: "drawer" }} />
              <Box sx={{ gridArea: "content" }}>
                {route?.component
                  ? React.createElement(route.component, route.props)
                  : null}
              </Box>
              <LoginDialog />
            </LocationContext.Provider>
          </HistoryContext.Provider>
        </RelayEnvironmentProvider>
      </ThemeProvider>
    );
  }
}
