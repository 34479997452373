/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import { darken, lighten, Palette } from "@material-ui/core/styles";
import createPalette from "@material-ui/core/styles/createPalette";

declare module "@material-ui/core" {
  interface Palette {
    highlight: string;
  }

  interface PaletteOptions {
    highlight: string;
  }
}

export const lightPalette: Palette = createPalette({
  mode: "light",
  background: {
    default: "rgba(240, 242, 245)",
  },
  primary: {
    main: "rgb(33,111,219)",
    dark: darken("rgb(33,111,219)", 0.05),
    light: lighten("rgb(33,111,219)", 0.05),
  },
  secondary: {
    main: "rgb(228,230,235)",
    dark: darken("rgb(228,230,235)", 0.05),
    light: lighten("rgb(228,230,235)", 0.05),
  },
  highlight: "rgba(240, 255, 0, 0.25)",
});

export const darkPalette: Palette = createPalette({
  mode: "dark",
  primary: {
    main: "rgb(69,153,255)",
    dark: lighten("rgb(69,153,255)", 0.05),
    light: darken("rgb(69,153,255)", 0.05),
    contrastText: "rgb(255,255,255)",
  },
  secondary: {
    main: "rgb(58,59,60)",
    dark: lighten("rgb(58,59,60)", 0.05),
    light: darken("rgb(58,59,60)", 0.05),
  },
  highlight: "rgba(240, 255, 0, 0.25)",
});
