/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SignUpInput = {
    name?: string | null;
    email?: string | null;
    username?: string | null;
    password?: string | null;
    timeZone?: string | null;
    languages?: Array<string> | null;
};
export type LoginDialogSignUpMutationVariables = {
    input: SignUpInput;
};
export type LoginDialogSignUpMutationResponse = {
    readonly signUp: {
        readonly me: {
            readonly id: string;
            readonly name: string | null;
            readonly email: string | null;
            readonly picture: string | null;
            readonly username: string;
        } | null;
    } | null;
};
export type LoginDialogSignUpMutation = {
    readonly response: LoginDialogSignUpMutationResponse;
    readonly variables: LoginDialogSignUpMutationVariables;
};



/*
mutation LoginDialogSignUpMutation(
  $input: SignUpInput!
) {
  signUp(input: $input) {
    me {
      id
      name
      email
      picture
      username
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignUpPayload",
    "kind": "LinkedField",
    "name": "signUp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "picture",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginDialogSignUpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginDialogSignUpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a10dc3e9494933ea17593596b50a56c3",
    "id": null,
    "metadata": {},
    "name": "LoginDialogSignUpMutation",
    "operationKind": "mutation",
    "text": "mutation LoginDialogSignUpMutation(\n  $input: SignUpInput!\n) {\n  signUp(input: $input) {\n    me {\n      id\n      name\n      email\n      picture\n      username\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cd27822bd05cd37d4d9634674b83db9e';
export default node;
