/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AppSearchQueryVariables = {
    search?: string | null;
    first?: number | null;
    after?: string | null;
};
export type AppSearchQueryResponse = {
    readonly works: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly path: string;
                readonly title: string;
                readonly subtitle: string | null;
                readonly picture: {
                    readonly filename: string;
                    readonly width: number | null;
                    readonly height: number | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type AppSearchQuery = {
    readonly response: AppSearchQueryResponse;
    readonly variables: AppSearchQueryVariables;
};



/*
query AppSearchQuery(
  $search: String
  $first: Int
  $after: String
) {
  works(search: $search, first: $first, after: $after) {
    edges {
      node {
        id
        path
        title
        subtitle
        picture {
          filename
          width
          height
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "WorkConnection",
    "kind": "LinkedField",
    "name": "works",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Work",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Picture",
                "kind": "LinkedField",
                "name": "picture",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e0f78bea9a9816be887be864a2bf4058",
    "id": null,
    "metadata": {},
    "name": "AppSearchQuery",
    "operationKind": "query",
    "text": "query AppSearchQuery(\n  $search: String\n  $first: Int\n  $after: String\n) {\n  works(search: $search, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        path\n        title\n        subtitle\n        picture {\n          filename\n          width\n          height\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c2bf52b3c22b10137266f6b5de6fd95d';
export default node;
