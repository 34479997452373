/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LangCode = "ar" | "az" | "bn" | "ca" | "cs" | "da" | "de" | "el" | "en" | "es" | "fa" | "fi" | "fr" | "he" | "hi" | "hu" | "id" | "it" | "ja" | "ko" | "lt" | "ne" | "nl" | "no" | "pl" | "pt" | "ro" | "ru" | "simple" | "sv" | "ta" | "tr" | "uk" | "vi" | "zh" | "%future added value";
export type workQueryVariables = {
    lang: LangCode;
    slug: string;
};
export type workQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly name: string | null;
        readonly email: string | null;
        readonly username: string;
        readonly picture: string | null;
        readonly " $fragmentRefs": FragmentRefs<"WorkActions_me">;
    } | null;
    readonly work: {
        readonly id: string;
        readonly lang: LangCode;
        readonly slug: string;
        readonly title: string;
        readonly subtitle: string | null;
        readonly summary: string;
        readonly picture: {
            readonly filename: string;
            readonly width: number | null;
            readonly height: number | null;
        } | null;
        readonly background: string | null;
        readonly links: {
            readonly amazon: string | null;
            readonly ozon: string | null;
        } | null;
        readonly membersCount: number | null;
        readonly " $fragmentRefs": FragmentRefs<"WorkActions_work">;
    } | null;
    readonly highlights: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"WorkHighlight_highlight">;
            } | null;
        } | null> | null;
    } | null;
};
export type workQuery = {
    readonly response: workQueryResponse;
    readonly variables: workQueryVariables;
};



/*
query workQuery(
  $lang: LangCode!
  $slug: String!
) {
  me {
    ...WorkActions_me
    id
    name
    email
    username
    picture
  }
  work(lang: $lang, slug: $slug) {
    ...WorkActions_work
    id
    lang
    slug
    title
    subtitle
    summary
    picture {
      filename
      width
      height
    }
    background
    links {
      amazon
      ozon
    }
    membersCount
  }
  highlights(lang: $lang, work: $slug) {
    edges {
      node {
        id
        ...WorkHighlight_highlight
      }
    }
  }
}

fragment SaveWorkDialog_me on User {
  id
  collections {
    id
    slug
    name
    privacy
  }
}

fragment SaveWorkDialog_work on Work {
  id
  collectionIds
}

fragment WorkActions_me on User {
  ...SaveWorkDialog_me
}

fragment WorkActions_work on Work {
  ...SaveWorkDialog_work
  id
  thumbsUpCount
  thumbsDownCount
  reactions
  collectionIds
}

fragment WorkHighlight_highlight on Highlight {
  id
  slug
  content
  createdBy {
    username
    id
  }
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picture",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Picture",
  "kind": "LinkedField",
  "name": "picture",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "background",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkLinks",
  "kind": "LinkedField",
  "name": "links",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amazon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ozon",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCount",
  "storageKey": null
},
v17 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "work",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkActions_me"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Work",
        "kind": "LinkedField",
        "name": "work",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkActions_work"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "HighlightConnection",
        "kind": "LinkedField",
        "name": "highlights",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HighlightEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Highlight",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WorkHighlight_highlight"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Collection",
            "kind": "LinkedField",
            "name": "collections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privacy",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Work",
        "kind": "LinkedField",
        "name": "work",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbsUpCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbsDownCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reactions",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "HighlightConnection",
        "kind": "LinkedField",
        "name": "highlights",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HighlightEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Highlight",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9d352fb9f16181ecb97b07698e3f84c",
    "id": null,
    "metadata": {},
    "name": "workQuery",
    "operationKind": "query",
    "text": "query workQuery(\n  $lang: LangCode!\n  $slug: String!\n) {\n  me {\n    ...WorkActions_me\n    id\n    name\n    email\n    username\n    picture\n  }\n  work(lang: $lang, slug: $slug) {\n    ...WorkActions_work\n    id\n    lang\n    slug\n    title\n    subtitle\n    summary\n    picture {\n      filename\n      width\n      height\n    }\n    background\n    links {\n      amazon\n      ozon\n    }\n    membersCount\n  }\n  highlights(lang: $lang, work: $slug) {\n    edges {\n      node {\n        id\n        ...WorkHighlight_highlight\n      }\n    }\n  }\n}\n\nfragment SaveWorkDialog_me on User {\n  id\n  collections {\n    id\n    slug\n    name\n    privacy\n  }\n}\n\nfragment SaveWorkDialog_work on Work {\n  id\n  collectionIds\n}\n\nfragment WorkActions_me on User {\n  ...SaveWorkDialog_me\n}\n\nfragment WorkActions_work on Work {\n  ...SaveWorkDialog_work\n  id\n  thumbsUpCount\n  thumbsDownCount\n  reactions\n  collectionIds\n}\n\nfragment WorkHighlight_highlight on Highlight {\n  id\n  slug\n  content\n  createdBy {\n    username\n    id\n  }\n  createdAt\n}\n"
  }
};
})();
(node as any).hash = '776b4acad584da66cbf5bde95a6dacce';
export default node;
