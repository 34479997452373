/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { Route } from "../../core";
import type Library from "./Library";

export default [
  {
    path: "/library",
    component: () => import(/* webpackChunkName: "library" */ "./Library"),
    response: () => ({
      title: "Library",
    }),
  } as Route<typeof Library>,
];
