/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { Route } from "../../core";
import type Explore from "./Explore";

export default [
  {
    path: "/explore",
    component: () => import(/* webpackChunkName: "explore" */ "./Explore"),
    response: () => ({
      title: "Explore",
    }),
  } as Route<typeof Explore>,
];
