/**
 * @copyright 2016-present Kriasoft (https://git.io/vMINh)
 */

import { LangCode } from "db/types";
import { graphql } from "relay-runtime";
import type { Route } from "../../core";
import type AddWork from "./AddWork";
import type Work from "./Work";
import type { workAddQueryResponse } from "./__generated__/workAddQuery.graphql";
import type { workQueryResponse } from "./__generated__/workQuery.graphql";

const langCodes = Object.keys(LangCode)
  .filter((x) => x !== "en")
  .join("|");

/**
 * User account settings route.
 */
export default [
  {
    path: "/add",
    query: graphql`
      query workAddQuery {
        me {
          id
        }
      }
    `,
    component: () => import(/* webpackChunkName: "add" */ "./AddWork"),
    response: () => ({
      title: "Add Work",
    }),
  } as Route<typeof AddWork, workAddQueryResponse>,
  {
    path: `/:lang(${langCodes})?/:slug([\\w-]+)`,
    query: graphql`
      query workQuery($lang: LangCode!, $slug: String!) {
        me {
          ...WorkActions_me
          id
          name
          email
          username
          picture
        }
        work(lang: $lang, slug: $slug) {
          ...WorkActions_work
          id
          lang
          slug
          title
          subtitle
          summary
          picture {
            filename
            width
            height
          }
          background
          links {
            amazon
            ozon
          }
          membersCount
        }
        highlights(lang: $lang, work: $slug) {
          edges {
            node {
              id
              ...WorkHighlight_highlight
            }
          }
        }
      }
    `,
    variables: (ctx: any) => ({
      lang: ctx.params.lang || "en",
      slug: ctx.params.slug,
    }),
    component: () => import(/* webpackChunkName: "work" */ "./Work"),
    response: (data) =>
      data.work && {
        title: `${data.work.title} · Goodparts`,
        description:
          data.work.subtitle ||
          "In search of the golden nuggets: The keys to work, wisdom, and wealth!",
        props: data,
        toolbar: {
          back: "/",
        },
      },
  } as Route<typeof Work, workQueryResponse>,
];
