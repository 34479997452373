// import { useTheme } from "@material-ui/core";
import * as React from "react";

export function useCustomerChat(enable = true): void {
  // const theme = useTheme();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!enable) return;

      // https://developers.facebook.com/docs/javascript/quickstart/
      (self as any).fbAsyncInit = function () {
        (self as any).FB.init({
          appId: "2323463484605817",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v10.0",
        });
      };

      let el: HTMLElement = document.createElement("div");
      el.setAttribute("id", "fb-root");
      document.body.appendChild(el);

      // https://developers.facebook.com/docs/messenger-platform/reference/web-plugins/#customer_chat
      const msg = "Hi! What is your favorite non-fiction book?";
      el = document.createElement("div");
      el.setAttribute("class", "fb-customerchat");
      el.setAttribute("attribution", "setup_tool");
      el.setAttribute("page_id", "109007421241420");
      //el.setAttribute("theme_color", theme.palette.primary.main);
      el.setAttribute("logged_in_greeting", msg);
      el.setAttribute("logged_out_greeting", msg);
      el.setAttribute("greeting_dialog_display", "hide");
      document.body.appendChild(el);

      el = document.createElement("script");
      el.setAttribute("id", "facebook-jssdk");
      el.setAttribute(
        "src",
        "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js#xfbml=1&version=v10.0&autoLogAppEvents=1",
      );
      document.head.appendChild(el);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
}
