// The TypeScript definitions below are automatically generated.
// Do not touch them, or risk, your modifications being lost.

export enum IdentityProvider {
  google = "google",
  apple = "apple",
  facebook = "facebook",
  github = "github",
  linkedin = "linkedin",
  microsoft = "microsoft",
  twitter = "twitter",
  yahoo = "yahoo",
  gamecenter = "gamecenter",
  playgames = "playgames",
}

export enum LangCode {
  en = "en",
  fr = "fr",
  de = "de",
  es = "es",
  ja = "ja",
  ru = "ru",
  pt = "pt",
  it = "it",
  zh = "zh",
  ar = "ar",
  fa = "fa",
  pl = "pl",
  nl = "nl",
  id = "id",
  uk = "uk",
  he = "he",
  sv = "sv",
  cs = "cs",
  ko = "ko",
  vi = "vi",
  ca = "ca",
  no = "no",
  fi = "fi",
  hu = "hu",
  tr = "tr",
  ro = "ro",
  el = "el",
  hi = "hi",
  bn = "bn",
  az = "az",
  simple = "simple",
  da = "da",
  lt = "lt",
  ne = "ne",
  ta = "ta",
}

export enum NotificationsMode {
  all = "all",
  best = "best",
  none = "none",
}

export enum Privacy {
  public = "public",
  unlisted = "unlisted",
  private = "private",
}

export enum Reaction {
  thumbs_up = "thumbs_up",
  thumbs_down = "thumbs_down",
}

export enum SourceType {
  book = "book",
  volume = "volume",
  user = "user",
  author = "author",
  audiobook = "audiobook",
  comics = "comics",
}

export enum SourceVendor {
  amazon = "amazon",
  google = "google",
  goodreads = "goodreads",
  bookmate = "bookmate",
}

export enum Status {
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
}

export type Collection = {
  id: string;
  user_id: string | null;
  slug: string;
  name: string;
  privacy: Privacy;
  position: number;
  volumes_count: number;
  created_at: Date;
  updated_at: Date;
};

export type CollectionVolume = {
  id: string;
  collection_id: string | null;
  work_id: string | null;
  created_at: Date;
};

export type Highlight = {
  id: string;
  work_id: string;
  section_id: string | null;
  created_by: string | null;
  approved_by: string | null;
  slug: string;
  content: any;
  content_tsv: string;
  reactions: any;
  likes_count: number;
  dislikes_count: number;
  likes_ratio: number;
  position: number;
  public: boolean;
  approved: boolean;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
};

export type Identity = {
  provider: IdentityProvider;
  id: string;
  user_id: string;
  username: string | null;
  email: string | null;
  email_verified: boolean | null;
  name: string | null;
  picture: string | null;
  given_name: string | null;
  family_name: string | null;
  locale: string | null;
  access_token: string | null;
  refresh_token: string | null;
  scopes: string[];
  token_type: string | null;
  created_at: Date;
  updated_at: Date;
  issued_at: Date | null;
  expires_at: Date | null;
};

export type Member = {
  member_id: string;
  work_id: string;
  user_id: string;
  notifications: NotificationsMode;
  highlights_count: number;
  comments_count: number;
  created_at: Date;
  updated_at: Date;
};

export type Section = {
  id: string;
  work_id: string;
  created_by: string | null;
  approved_by: string | null;
  slug: string;
  name: string;
  highlights_count: number;
  comments_count: number;
  position: number;
  approved: boolean;
  created_at: Date;
  updated_at: Date;
};

export type Source = {
  id: string;
  vendor: SourceVendor;
  type: SourceType;
  imported: any;
  parsed: any;
  imported_at: Date;
  parsed_at: Date;
};

export type User = {
  id: string;
  username: string;
  email: string | null;
  email_verified: boolean;
  password: string | null;
  name: string | null;
  picture: string | null;
  given_name: string | null;
  family_name: string | null;
  time_zone: string | null;
  locale: string | null;
  languages: any;
  admin: boolean;
  blocked: boolean;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
  last_login: Date | null;
};

export type Work = {
  id: string;
  lang: LangCode;
  slug: string;
  title: string;
  subtitle: string | null;
  published_date: Date | null;
  summary: any;
  picture: any;
  background: string | null;
  links: any;
  asin: string | null;
  isbn10: string | null;
  isbn13: string | null;
  page_count: number | null;
  members_count: number;
  highlights_count: number;
  comments_count: number;
  thumbs_up_count: number;
  thumbs_down_count: number;
  approved: boolean;
  created_by: string | null;
  approved_by: string | null;
  created_at: Date;
  updated_at: Date;
  tsv: string | null;
};

export type WorkReaction = {
  work_id: string;
  user_id: string;
  reaction: Reaction;
  status: Status;
  created_at: Date;
};
