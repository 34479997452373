/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import * as React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function Logo(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon role="img" viewBox="0 0 1024 1024" {...props}>
      <path d="M25.4 999c11.4-42.8 21.8-81.9 32.3-121 9-33.5 18.7-66.9 26.5-100.6 2-8.7.9-20.1-3.3-27.8C-74.3 460.4 86 106.2 405.7 32.8c298.7-68.5 590.8 153 601.4 459 6.6 190.4-72.8 340.6-234.4 440.2-160.5 99-328.4 99.1-495.9 12.7-12.1-6.2-22.2-7.2-34.9-3.7-70.8 19.5-141.8 38.1-216.5 58zm129.8-131.3c50.1-13.4 97.3-25.6 144.2-38.9 14.6-4.1 26.3-3 39.8 4.5 241 133.9 542.1-43.9 541.8-319.5-.2-212.9-182.8-380.9-395.5-364.1-241.2 19.1-398 264.1-313.9 490.4 32.1 44.8 22.7 90.9 6.1 138.2-8.7 24.9-14.1 51-20.8 76.6-.7 3.1-.9 6.5-1.7 12.8z" />
      <path d="M353.9 673.1c10.3-20.3 20-39.6 29.7-58.9 6.2-12.4 3.8-19.2-10.7-24.4-56-20-82.1-82-58.2-135.3 24-53.5 92.2-76.9 143.3-47.6 36.8 21.1 51.1 55.7 49.3 96.8-3.3 70.3-81.2 156.4-153.4 169.4zM570.3 672.9c12.1-24.6 24.1-48.9 37.1-75.3-10-3.9-16.4-6.2-22.7-8.9-40.2-17-64-54-62.5-97.5 1.4-41.6 28.7-77.8 68.8-91.3 40.3-13.6 84-2.5 108.9 28.9 26.9 33.9 30.3 72.7 15.8 111.9C691 607.4 640.3 648.1 575.4 673c-1.5.6-3.4-.1-5.1-.1z" />
    </SvgIcon>
  );
}
