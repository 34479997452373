/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginDialogResetMutationVariables = {
    email?: string | null;
    password?: string | null;
    token?: string | null;
};
export type LoginDialogResetMutationResponse = {
    readonly resetPassword: string | null;
};
export type LoginDialogResetMutation = {
    readonly response: LoginDialogResetMutationResponse;
    readonly variables: LoginDialogResetMutationVariables;
};



/*
mutation LoginDialogResetMutation(
  $email: String
  $password: String
  $token: String
) {
  resetPassword(email: $email, password: $password, token: $token)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ScalarField",
    "name": "resetPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginDialogResetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginDialogResetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7c4ef882056d4fd342defc9104896396",
    "id": null,
    "metadata": {},
    "name": "LoginDialogResetMutation",
    "operationKind": "mutation",
    "text": "mutation LoginDialogResetMutation(\n  $email: String\n  $password: String\n  $token: String\n) {\n  resetPassword(email: $email, password: $password, token: $token)\n}\n"
  }
};
})();
(node as any).hash = 'a7bdfeefdb33d18416270fcc0bc5d003';
export default node;
