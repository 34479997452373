/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  InputAdornment,
  Link,
  TextField,
} from "@material-ui/core";
import { debounce } from "@material-ui/core/utils";
import { Search } from "@material-ui/icons";
import * as React from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import { useHistory } from "../hooks";
import { AppSearchQuery } from "./__generated__/AppSearchQuery.graphql";

const worksQuery = graphql`
  query AppSearchQuery($search: String, $first: Int, $after: String) {
    works(search: $search, first: $first, after: $after) {
      edges {
        node {
          id
          path
          title
          subtitle
          picture {
            filename
            width
            height
          }
        }
      }
    }
  }
`;

function renderInput(params: AutocompleteRenderInputParams): React.ReactNode {
  const { InputProps, ...other } = params;

  return (
    <TextField
      sx={{
        padding: 0,
        "& .MuiOutlinedInput-root": {
          borderRadius: "20px",
          borderRadius: "20px",
          backgroundColor: (theme) => theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root:not(.Mui-focused)": {
          borderRadius: "20px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      placeholder="Search by title, author, or keywords..."
      InputProps={{
        sx: {
          "&.MuiAutocomplete-inputRoot.MuiOutlinedInput-root": {
            paddingTop: "1px",
            paddingBottom: "1px",
          },
        },
        ...InputProps,
        startAdornment: (
          <InputAdornment
            position="start"
            children={<Search color="disabled" />}
          />
        ),
      }}
      {...other}
    />
  );
}

function renderOption(
  props: React.HTMLProps<HTMLLIElement>,
  option: Option,
  state: AutocompleteRenderOptionState,
): React.ReactNode {
  const { id, path, title, subtitle, picture } = option;

  const height = 60;
  const width = Math.round((height * picture.width) / picture.height);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <li css={{ display: "flex" }} {...props} key={id}>
      <img
        css={{ marginRight: "16px", backgroundColor: "rgba(0,0,0,0.12)" }}
        src={`/img/w_${width},h_${height}/${picture.filename}`}
        alt={title}
        width={width}
        height={height}
      />
      <span>
        <Link
          href={path}
          color="inherit"
          underline="none"
          children={title}
          onClick={handleClick}
        />
        <br />
        <Link
          css={{ color: "rgba(0,0,0,0.48)" }}
          href={path}
          color="inherit"
          underline="none"
          children={subtitle}
          onClick={handleClick}
        />
      </span>
    </li>
  );
}

function getOptionLabel(option: Option): string {
  return option.title ?? option;
}

type Option = {
  id: string;
  path: string;
  title: string;
  subtitle: string;
  picture: {
    filename: string;
    width: number;
    height: number;
  };
};

type AppSearchProps = Omit<
  AutocompleteProps<Option, true, true, true>,
  "id" | "options" | "renderInput"
>;

export function AppSearch(props: AppSearchProps): JSX.Element {
  const { sx, ...other } = props;

  const [works, setWorks] = React.useState<Option[]>([] as Option[]);
  const relay = useRelayEnvironment();
  const history = useHistory();

  const handleInputChange = React.useMemo(
    () =>
      debounce(
        (
          event: React.SyntheticEvent,
          value: string,
          reason: AutocompleteInputChangeReason,
        ) => {
          if (!(reason === "input" && value)) return;
          fetchQuery<AppSearchQuery>(relay, worksQuery, {
            search: value,
            first: 20,
          })
            .toPromise()
            .then((data: AppSearchQuery["response"]) => {
              if (data?.works?.edges) {
                setWorks(
                  data.works.edges.reduce(
                    (acc, x) => (x?.node ? [...acc, x.node as Option] : acc),
                    [] as Option[],
                  ),
                );
              }
            });
        },
        200,
      ),
    [],
  );

  const handleChange = React.useCallback(
    (
      event: React.SyntheticEvent,
      value: (string | Option)[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<Option>,
    ): void => {
      // console.log("onChange", value, reason, details);
      if (reason === "select-option" && details) {
        history.push(details.option.path);
      }
    },
    [],
  );

  return (
    <Autocomplete<Option, true, true, true>
      sx={{ ...sx, padding: 0 }}
      id="search"
      options={works}
      renderInput={renderInput}
      renderOption={renderOption}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      fullWidth
      freeSolo
      {...props}
    />
  );
}
