/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import { graphql } from "relay-runtime";

import UserList from "./UserList";
import WorkList from "./WorkList";
import { Route } from "../core/router";
import type { adminWorkListQueryResponse } from "./__generated__/adminWorkListQuery.graphql";
import type { adminUserListQueryResponse } from "./__generated__/adminUserListQuery.graphql";

export default [
  {
    path: "/admin/works",
    query: graphql`
      query adminWorkListQuery {
        me {
          admin
        }

        works(first: 9999) {
          edges {
            node {
              id
              slug
              title
              subtitle
              summary
              updatedAt
              picture {
                filename
                width
                height
              }
            }
          }
        }
      }
    `,
    component: () => import(/* webpackChunkName: "admin" */ "./WorkList"),
    response(data) {
      if (!data?.me?.admin) return;

      return {
        title: "Works",
        props: data,
      };
    },
  } as Route<typeof WorkList, adminWorkListQueryResponse>,
  {
    path: "/admin/users",
    query: graphql`
      query adminUserListQuery {
        me {
          admin
        }

        users(first: 9999) {
          edges {
            node {
              id
              name
              email
              username
              picture
              createdAt
            }
          }
        }
      }
    `,
    component: () => import(/* webpackChunkName: "admin" */ "./UserList"),
    response(data) {
      if (!data?.me?.admin) return;

      return {
        title: "Users",
        props: data,
      };
    },
  } as Route<typeof UserList, adminUserListQueryResponse>,
];
