/**
 * Web application entry point.
 *
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import type { To } from "history";
import history from "history/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./common";
import { createRelay } from "./core/relay";

// Dehydrate the initial API response and initialize a Relay store
// https://developer.mozilla.org/docs/Web/HTML/Element/script#embedding_data_in_html
const data = (document.getElementById("data") as HTMLScriptElement).text;
const records = data ? JSON.parse(data) : undefined;
const relay = createRelay({ records });
const toolbar = records?.["client:root:toolbar"];

/**
 * Go back in history if its available, otherwise to go the fallback URL path
 */
history.goBack = ((initialKey) =>
  function goBack(fallback?: To) {
    if (history.location.key === initialKey && fallback) {
      history.replace(fallback);
    } else {
      history.back();
    }
  })(history.location.key);

// Render the top-level React component
ReactDOM.render(
  <App history={history} relay={relay} toolbar={toolbar} />,
  document.getElementById("root"),
);
